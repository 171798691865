<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="openModal"
      :loading="loading"
      @submit="handleSubmit(submit)"
      submit="Apply"
      :disabled-button="totalAmount !== 100"
      @close="$emit('close'), (forms = [])"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple capitalize">
          Criteria
        </p>
      </template>
      <div class="w-full flex flex-col justify-end items-end my-6">
        <button class="flex justify-center gap-3 items-center cursor-pointer" @click="isAddCriteria = true">
          <Icon size="s" icon-name="icon-plus" />
          <p class="text-flame font-semibold">
            Add New Custom Criteria
          </p>
        </button>
      </div>
      <div>
        <table aria-label="table" class=" w-full mt-4">
          <thead>
            <tr>
              <th
                class="uppercase"
                id="header"
                v-for="(header, index) in headers"
                :key="index"
              >
                <p>{{ header.title }}</p>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, i) in forms"
              :key="i"
              class="tableBody text-sm w-full "
              style="height: 48px; "
            >
              <td class="w-1/2">
                <v-select
                  :clearable="false"
                  placeholder="--Enter--"
                  class="style-select capitalize"
                  style="height: 32px"
                  taggable
                  v-model="data.criteria"
                  :options="criteriasOptions"
                  :filterable="false"
                  :reduce="option => option.criteria"
                  label="criteria"
                  :selectable="isSelectable"
                >
                  <template #option="{ criteria, editDelete, isSelectable }">
                    <div @mouseenter="hoveredItem = criteria" @mouseleave="hoveredItem = null">
                       <div :class="isSelectable ? 'hover:text-romanSilver' : 'hover:text-white'" class="flex justify-between items-center gap-4">
                        <span class="text-base cursor-pointer whitespace-normal">{{ criteria }}</span>
                        <div v-if="editDelete" class="flex justify-start items-center gap-2">
                          <button :disabled="isSelectable" @click.stop="criteriaOption = criteria, isEditCriteria = true, criteriaIndex = i">
                            <icon icon-name="edit" size="xs" :class-name="hoveredItem ? 'text-white' : 'text-blueCrayola'" />
                          </button>
                          <button :disabled="isSelectable" @click.stop="criteriaOption = criteria, isDeleteCriteria = true, criteriaIndex = i">
                            <icon icon-name="delete" size="xs" :class-name="hoveredItem ? 'text-white' : 'text-flame'" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                </v-select>
              </td>
              <td class=""></td>
              <td class="w-1/2">
                <c-text
                  placeholder="--Enter--"
                  type="number"
                  min="0"
                  v-model="data.weight"
                />
              </td>
              <td />
              <td v-if="forms.length > 1">
                <Icon
                  size="s"
                  @click.native="removeForm(i)"
                  class="text-flame cursor-pointer"
                  icon-name="close"
                />
              </td>
            </tr>
            <tr />
            <tr v-if="totalAmount < 100">
              <button class="flex ml-6 mt-6" @click="addForm">
                <Icon size="s" icon-name="icon-plus" />
                <p class="ml-3 text-flame font-semibold">
                  Add
                </p>
              </button>
            </tr>
            <tr class="h-7" />

            <tr
              style="height: 52px; border-spacing: 15px 0;background: #F7F7FF !important;"
              class="mt-4 bg-ghostWhite"
              v-if="forms.length > 0"
            >
              <td class="font-bold text-lg text-darkPurple ">
                Total
              </td>
              <td />
              <td class="font-bold">
                {{ totalAmount }}
              </td>
              <td />
              <td />
            </tr>
          </tbody>
        </table>
      </div>

      <AddCriteria v-if="isAddCriteria"
        :disableAddBtn="disableAddBtn"
        @cancel="isAddCriteria = false"
        @add-criteria="addCriteria"
      />
      <DeleteCriteria v-if="isDeleteCriteria"
        @cancel="isDeleteCriteria = false"
        :criteriaOption="criteriaOption"
        :disableDelBtn="disableDelBtn"
        @delete-criteria="deleteCriteria"
      />
      <EditCriteria v-if="isEditCriteria"
        @cancel="isEditCriteria = false"
        :criteriaOption="criteriaOption"
        :disableEditBtn="disableEditBtn"
        @edit-criteria="editCriteria"
      />
    </RightSideBar>
    <Modal v-if="openDelete">
      <Card class=" p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold  ml-4 my-5 text-center">
            Are you sure you want to delete this {{ modalType }}?
          </p>
          <div class="flex ">
            <Button class="bg-dynamicBackBtn text-white" @click="deleteItem">
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="openDelete = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import "vue-select/dist/vue-select.css";

export default {
  name: "Criteria",
  components: {
    ValidationObserver,
    RightSideBar: () => import("@/components/RightSideBar"),
    vSelect: () => import("vue-select"),
    Modal: () => import("@/components/Modal"),
    Card: () => import("@/components/Card"),
    Button: () => import("@/components/Button"),
    CText: () => import("@scelloo/cloudenly-ui/src/components/text"),
    AddCriteria: () => import("./criteriaActions/AddCriteria"),
    DeleteCriteria: () => import("./criteriaActions/DeleteCriteria"),
    EditCriteria: () => import("./criteriaActions/EditCriteria")
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    modalType: {
      type: String,
      default: "addition"
    },
    criteriaSettings: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      forms: [],
      openDelete: false,
      itemId: "",
      itemIndex: 0,
      taxableOptions: [
        { name: "Yes", id: "true" },
        { name: "No", id: "false" }
      ],
      criterias: [ 
        {criteria: "Dedication", weight: 0, editDelete: false, isSelectable: false},
        {criteria: "Innovativeness" , weight: 0, editDelete: false, isSelectable: false},
        {criteria: "Active Collaboration", weight: 0, editDelete: false, isSelectable: false},
        {criteria: "Punctuality", weight: 0, editDelete: false, isSelectable: false},
        {criteria: "Detail Orientation", weight: 0, editDelete: false, isSelectable: false},
        {criteria: "Accountability", weight: 0, editDelete: false, isSelectable: false}      
      ],
      headers: [
        { title: "Criteria" },
        { title: "" },
        { title: "Weight" },
        { title: "" }
      ],
      loading: false,

      isAddCriteria: false,
      isDeleteCriteria: false,
      isEditCriteria: false,
      disableAddBtn: false,
      disableDelBtn: false,
      disableEditBtn: false,
      criteriaIndex: 0,

      criteriaOption: '',
      hoveredItem: null,
    };
  },
  watch: {
    openModal() {
      if (this.openModal) {
        if (this.criteriaSettings.length > 0) {
          const selected = this.criteriaSettings.filter(criteriaSetting => criteriaSetting.isSelectable);
  
          if(selected.length > 0){
              this.forms = selected;
              this.populateCriteriaOptions(this.criteriaSettings)
          }else{
            const criteriaNames = this.criterias.map(item => item.criteria);
            this.forms = this.criteriaSettings.map(criteriaSetting => {
              return ({
                ...criteriaSetting, 
                editDelete: !criteriaNames.includes(criteriaSetting.criteria) || false, 
                isSelectable: true 
                })
            }); 
            this.populateCriteriaOptions(this.forms)
          }
        } else {
          this.criterias = this.criterias
          this.addForm();
        }
      }
    }
  },
  computed: {
    totalAmount: {
      get() {
        let result = 0;
        this.forms.forEach(item => {
          result += Number(item.weight);
        });
        return result;
      },
      set() {}
    },
    selectedFormValues() {
      return this.forms
        .filter(form => form.criteria !== null)
        .map(form => form.criteria);
    },
    criteriasOptions() {
      return this.criterias.map(option => ({
        ...option,
        isSelectable: this.selectedFormValues.includes(option.criteria) || option.isSelectable
      }));
    }
  },
  methods: {
    addForm() {
      this.forms.push({
        criteria: null,
        weight: null,
        editDelete: true, 
        isSelectable: true
      });
    },

    removeForm(index) {
      if (index > 0 || this.forms.length > 1) this.forms.splice(index, 1);
    },

    submit() {
      const allCriterias = this.criterias.map(item => {
        const match = this.forms.find(form => form.criteria === item.criteria);
        return match ? { ...item, ...match } : item;
      });

      this.$emit("criteriaForm", allCriterias);
      this.$emit("close");
      this.forms = [];
    },

    populateCriteriaOptions(criterias) {
      const criteriaNames = this.criterias.map(item => item.criteria);

      criterias.map(option => {
        if(!criteriaNames.includes(option.criteria)){
            return this.criterias.push({criteria: option.criteria, weight: option.weight, editDelete: true, isSelectable: false});
        }
      })
    },

    // UpdateEachFormsRow(oldOption, newOption){
    //   this.forms = this.forms.map(row => {
    //     if (row.criteria === oldOption) {
    //       return { ...row, criteria: newOption };
    //     }
    //     return row;
    //   });
    // },

    isSelectable(option) {
      return !option.isSelectable;
    },

    async addCriteria(newCriteria) {
      const criteriaNames = this.criterias.map(item => item.criteria);
      if (newCriteria && !criteriaNames.includes(newCriteria)) {
        this.criterias.push({criteria: newCriteria, weight: 0, editDelete: true, isSelectable: false});
        this.disableAddBtn = true;
        const payload = this.criterias;

        try {
          await this.$_updateCriterias(payload); 
          this.disableAddBtn = false;
          this.isAddCriteria = false;

          return this.$toasted.success(`Criteria added successfully.`,{duration: 6000});
        } catch (error) {
          this.disableAddBtn = false;
          this.isAddCriteria = false;
          return this.$toasted.error(`Error adding new criteria: please check your connectivity!`,
             {duration: 6000}
            )
        }
      } else {
        return this.$toasted.error(`Critera already exists or is empty field.`,{duration: 6000})
      }
    },

    async deleteCriteria(option) {
      this.criterias = this.criterias.filter(item => item.criteria !== option);
      this.disableDelBtn = true; 
      const payload = this.criteria;
      
      try {
        await this.$_updateCriterias(payload);    
        this.disableDelBtn = false;
        this.isDeleteCriteria = false;

        return this.$toasted.success(`Criteria deleted successfully.`,{duration: 6000});
      } catch (error) {
        this.disableDelBtn = false;
        this.isDeleteCriteria = false;
        return this.$toasted.error(`Error deleting criteria: please check your connectivity!`,
            {duration: 6000}
          )
      }
    },

    async editCriteria(oldOption, newOption) {
      const criteriaNames = this.criterias.map(item => item.criteria);

      if (newOption && !criteriaNames.includes(newOption)) {
        const index = this.criterias.findIndex(item => item.criteria === oldOption);
        // Created a new array with the updated object
        const newCriterias = this.criterias.map((item, idx) => idx === index ? { ...item, criteria: newOption } : item);
        this.criterias = newCriterias;
        // this.UpdateEachFormsRow(oldOption, newOption)
        const payload = this.criterias;

        this.disableEditBtn = true;
        try {
          await this.$_updateCriterias(payload); 
          this.disableEditBtn = false;
          this.isEditCriteria = false;

          return this.$toasted.success(`Criteria edited successfully.`,{duration: 6000});
        } catch (error) {
          this.disableEditBtn = false;
          this.isEditCriteria = false;
          return this.$toasted.error(`Error editing criteria: please check your connectivity!`,
              {duration: 6000}
            )
        }
       
      } else {
        return this.$toasted.error(`Critera already exists or is empty field.`,{duration: 6000})
      }
    }
  }
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: white;
}
.wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-select .dropdown-menu .dropdown-item.disabled {
  color: #ccc !important;
  pointer-events: none !important;
}
</style>